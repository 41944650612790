// @ts-strict-ignore
import React from "react"

import moment from "moment"

import Collapse from "@kiwicom/orbit-components/lib/Collapse"
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Loading from "@kiwicom/orbit-components/lib/Loading"
import Separator from "@kiwicom/orbit-components/lib/Separator"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import Alert from "@kiwicom/orbit-components/lib/icons/Alert"
import Bus from "@kiwicom/orbit-components/lib/icons/Bus"
import CheckCircle from "@kiwicom/orbit-components/lib/icons/CheckCircle"
import CloseCircle from "@kiwicom/orbit-components/lib/icons/CloseCircle"

import CardList from "components/card-list"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"

import { fetchFromAPIBase } from "utils/fetch-utils"

import { ChargeMetric } from "./charge-reservation"
import { VehicleChargingPort, VehicleRegistration } from "./interfaces"
import { RegisterVehicleChargingPort } from "./register-vehicle-charging-port"
import { chargingPortNameWithPosition } from "./utils"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface VehicleChargingPortsListProps {}

interface VehicleChargingPortsListState {
  vehicleChargingPorts?: Array<VehicleChargingPort>
  selectedVehicleChargingPortId?: number
}

export default class VehicleChargingPortsList extends React.Component<
  VehicleChargingPortsListProps,
  VehicleChargingPortsListState
> {
  constructor(props: VehicleChargingPortsListProps) {
    super(props)
    this.state = {
      vehicleChargingPorts: null,
      selectedVehicleChargingPortId: null,
    }
    this.refreshData = this.refreshData.bind(this)
  }

  getVehicleChargingPorts() {
    fetchFromAPIBase({
      path: "/v1/charging/vehicle_charging_ports/list/",
    }).subscribe((response) => {
      if (response && !response.error) {
        this.setState({
          vehicleChargingPorts: response,
        })
      }
    })
  }

  refreshData() {
    this.getVehicleChargingPorts()
  }

  buildClickHandler({ id }: VehicleChargingPort) {
    return () => {
      if (this.state.selectedVehicleChargingPortId === id) {
        // Unselect this vehicle charging port
        this.setState({ selectedVehicleChargingPortId: null })
      } else {
        this.setState({ selectedVehicleChargingPortId: id })
      }
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  buildExtraRegistrationDetails(vehicleChargingPort: VehicleChargingPort) {
    if (!vehicleChargingPort.registrations) {
      return null
    } else {
      return vehicleChargingPort.registrations.map(
        (registration: VehicleRegistration) => {
          // TODO: return charge point in request
          return (
            <Text key={registration.id}>
              Plug in with Cable {registration.connector.localConnectorId} at{" "}
              {registration.chargePointName} before{" "}
              {moment(registration.timeout).format("LT on Do of MMMM")} to
              register
            </Text>
          )
        }
      )
    }
  }

  registrationRequiredText(vehicleChargingPort: VehicleChargingPort) {
    if (vehicleChargingPort.registrations.length === 0) {
      return "Registration Required"
    } else {
      const registration = vehicleChargingPort.registrations[0]
      return `Registration Pending until ${moment(registration.timeout).format(
        "LT"
      )}`
    }
  }

  buildVehicleCharingPortLabel(vehicleChargingPort: VehicleChargingPort) {
    return (
      <Stack direction="column" spacing="small">
        <Stack direction="row" spacing="XSmall" shrink>
          <Heading as="h5" type="title1">
            {chargingPortNameWithPosition(vehicleChargingPort)}
          </Heading>
        </Stack>
        <Stack direction="row" align="center" spacing="large" inline shrink>
          <Text>{vehicleChargingPort.licensePlate}</Text>
          <ChargeMetric key="fleet" icon={<Bus />}>
            {vehicleChargingPort.fleet.name}
          </ChargeMetric>

          {vehicleChargingPort.idTag ? (
            <ChargeMetric
              key="registration"
              icon={<CheckCircle color="success" />}
            >
              Registered
            </ChargeMetric>
          ) : (
            <ChargeMetric key="registration" icon={<Alert color="warning" />}>
              {this.registrationRequiredText(vehicleChargingPort)}
            </ChargeMetric>
          )}
        </Stack>
      </Stack>
    )
  }

  viewContent(vehicleChargingPort) {
    return (
      <Stack>
        <Separator />

        <Text weight="bold" as="span">
          Registered{" "}
          {vehicleChargingPort.idTag ? (
            <CheckCircle color="success" />
          ) : (
            <CloseCircle color="critical" />
          )}{" "}
        </Text>
        <RegisterVehicleChargingPort
          vehicleChargingPort={vehicleChargingPort}
          updateTrigger={this.refreshData}
        >
          {vehicleChargingPort.idTag ? "Re-Register" : "Register"}
        </RegisterVehicleChargingPort>
        {this.buildExtraRegistrationDetails(vehicleChargingPort)}
        <Text weight="bold" as="span">
          License Plate:
        </Text>
        <Text> {vehicleChargingPort.licensePlate}</Text>

        <Text weight="bold" as="span">
          Battery:{" "}
        </Text>
        <Text>{vehicleChargingPort.batteryCapacity}kWh</Text>

        <Text weight="bold" as="span">
          Supported Connectors:
        </Text>
        <Text> {vehicleChargingPort.supportedConnectors}</Text>

        <Text weight="bold" as="span">
          Fleet:
        </Text>
        <Text> {vehicleChargingPort.fleet.name} </Text>
      </Stack>
    )
  }

  buildVehicleCards() {
    if (!this.state.vehicleChargingPorts) {
      return <Loading></Loading>
    }
    return this.state.vehicleChargingPorts.map(
      (vehicleChargingPort: VehicleChargingPort) => {
        return (
          <EmberCard key={vehicleChargingPort.id}>
            <EmberCardSection>
              <Collapse
                key={vehicleChargingPort.id}
                label={this.buildVehicleCharingPortLabel(vehicleChargingPort)}
                expanded={
                  this.state.selectedVehicleChargingPortId ===
                  vehicleChargingPort.id
                }
                onClick={this.buildClickHandler(vehicleChargingPort)}
              >
                {this.viewContent(vehicleChargingPort)}
              </Collapse>
            </EmberCardSection>
          </EmberCard>
        )
      }
    )
  }

  render(): JSX.Element {
    return <CardList title="Vehicles">{this.buildVehicleCards()}</CardList>
  }
}
