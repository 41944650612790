// @ts-strict-ignore
import React from "react"

import moment from "moment"

import Alert from "@kiwicom/orbit-components/lib/Alert"
import Button from "@kiwicom/orbit-components/lib/Button"
import Popover from "@kiwicom/orbit-components/lib/Popover"
import Select from "@kiwicom/orbit-components/lib/Select"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"
import Tile from "@kiwicom/orbit-components/lib/Tile"

import { fetchFromAPIBase } from "utils/fetch-utils"

import { ChargePoint, VehicleChargingPort } from "./interfaces"
import { chargingPortNameWithPosition } from "./utils"

interface RegisterVehicleChargingPortProps {
  vehicleChargingPort: VehicleChargingPort
  updateTrigger?: () => void
  children: React.ReactNode
}

interface RegisterVehicleChargingPortState {
  chargePoints?: Array<ChargePoint>
  selectedConnector?: number
  errorMessage?: string
}

export class RegisterVehicleChargingPort extends React.Component<
  RegisterVehicleChargingPortProps,
  RegisterVehicleChargingPortState
> {
  constructor(props: RegisterVehicleChargingPortProps) {
    super(props)
    this.state = {
      chargePoints: null,
      selectedConnector: null,
    }
    this.setSelectedConnector = this.setSelectedConnector.bind(this)
  }

  componentDidMount() {
    this.getChargePoints()
  }

  getChargePoints() {
    fetchFromAPIBase({ path: "/v1/charging/charge_points/" }).subscribe(
      (response) => {
        if (response && !response.error) {
          this.setState({ chargePoints: response })
          if (!this.state.selectedConnector) {
            // set the selected connector if we haven't already
            this.setState((previousState) => {
              return {
                selectedConnector:
                  previousState.selectedConnector ||
                  this.buildChargePointOptions()[0]?.value,
              }
            })
          }
        }
      }
    )
  }

  setSelectedConnector(event) {
    this.setState({
      selectedConnector: event.target.value,
    })
  }

  buildChargePointOptions() {
    const output: Array<any> = []
    if (!this.state.chargePoints) {
      return output
    }

    for (const chargePoint of this.state.chargePoints) {
      for (const connector of chargePoint.connectors) {
        output.push({
          key: connector.id,
          value: connector.id,
          label: `${chargePoint.name} - Cable ${connector.localConnectorId}`,
        })
      }
    }
    return output
  }

  register(vehicleChargingPort: VehicleChargingPort) {
    fetchFromAPIBase({
      path: "/v1/charging/vehicle_charging_ports/register/",
      method: "POST",
      body: {
        connector_id: this.state.selectedConnector,
        vehicle_charging_port_id: vehicleChargingPort.id,
      },
    }).subscribe((response) => {
      if (response && response.error) {
        if (
          response.error?.response.error === "AnotherVehicleRegistrationExists"
        ) {
          this.setState({
            errorMessage: `Another vehicle is registering. Use another cable or wait until the cable is free ${moment(
              response.error.response.timeout
            ).from(moment())}`,
          })
        } else {
          this.setState({ errorMessage: "An error occurred" })
        }
      }
      this.props.updateTrigger && this.props.updateTrigger()
    })
  }

  render() {
    return (
      <Popover
        content={
          <Tile
            title={`Registering ${chargingPortNameWithPosition(
              this.props.vehicleChargingPort
            )}`}
            noHeaderIcon
          >
            <Stack>
              <Text>
                Select which cable you will register your vehicle's charging
                port with.
              </Text>
              <Text>
                The next vehicle to be plugged into this cable will be
                registered as{" "}
                {chargingPortNameWithPosition(this.props.vehicleChargingPort)}.
              </Text>
              <Text>
                Once you click submit, you will have 10 minutes to plug{" "}
                {chargingPortNameWithPosition(this.props.vehicleChargingPort)}{" "}
                in.
              </Text>
              <Select
                options={this.buildChargePointOptions()}
                onChange={this.setSelectedConnector}
                value={this.state.selectedConnector}
              ></Select>
              <Stack direction="column">
                <Button
                  onClick={() => this.register(this.props.vehicleChargingPort)}
                >
                  Submit
                </Button>
                {this.state.errorMessage && (
                  <Alert>{this.state.errorMessage}</Alert>
                )}
              </Stack>
            </Stack>
          </Tile>
        }
      >
        <TextLink size="small">{this.props.children}</TextLink>
      </Popover>
    )
  }
}
