import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import Stack from "@kiwicom/orbit-components/lib/Stack"

type CardListType = {
  title: string
  children: React.ReactNode
}

const CardList = ({ title, children }: CardListType) => {
  return (
    <Stack spacing="small">
      <Heading type="title3">{title}</Heading>
      {children}
    </Stack>
  )
}

export default CardList
