import React from "react"

import Stack from "@kiwicom/orbit-components/lib/Stack"

import VehicleChargingPortsList from "components/charging/vehicle-charging-ports"
import Container from "components/container"
import { ChargeLayout } from "components/layout-custom"

const Page = () => {
  return (
    <ChargeLayout title="Vehicles">
      <Container>
        <Stack>
          <VehicleChargingPortsList />
        </Stack>
      </Container>
    </ChargeLayout>
  )
}

export default Page
